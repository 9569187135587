import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CommonService } from '../common.service';

@Injectable({ providedIn: 'root' })
export class PrintProductQuoteService {
  constructor(private _http: HttpClient, private _cs: CommonService) { }

  getQuoteDetails = (quote_id) => {
    return this._http.post(environment.API_URL + 'print-products/quote/get-details', { 'id': quote_id }, this._cs.setHeader());
  }

  getPartialQuoteDetails = (quote_id) => {
    return this._http.post(environment.API_URL + 'print-products/quote/get-partial-details', { quote_id }, this._cs.setHeader());
  }

  updateQuote = (id, markup, profit, total) => {
    return this._http.post(environment.API_URL + 'print-products/quote/update-quote',
      { 'id': id, 'markup': markup, 'profit': profit, 'total': total }, this._cs.setHeader());
  }

  convertToOrder = (postData) => {
    return this._http.post(environment.API_URL + 'print-products/quote/convert-to-order',
      { data: postData }, this._cs.setHeader());
  }
  getClientDetailByQuoteId = (quote_id) => {
    return this._http.post(environment.API_URL + 'print-products/quote/get-client-details', { 'id': quote_id }, this._cs.setHeader());
  }
  checkClientAddress = (postData) => {
    return this._http.post(environment.API_URL + 'print-products/quote/check-shipping-address',
      postData, this._cs.setHeader());
  }
}
